<!-- 留言详情 -->
<template>
    <div class="MsgDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">留言详情</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form"  class="form" inline label-width="120px">
            <el-form-item label="" >
                <el-input  class="textArea-width"  type="textarea"
                    :rows="10" v-model="form.explain" placeholder="请输入" disabled></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form: {
                   explain: "" //说明
               }, 
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped="scoped">
    .MsgDetails{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        width: 80%;
        margin: 30px auto;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    ::v-deep .el-textarea.is-disabled .el-textarea__inner{
        background-color: #FFFFFF;
        color: #333;
    }
</style>
